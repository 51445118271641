'use client';

import React, {
  createContext, Suspense, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import dynamic from 'next/dynamic';
import theme from '@/theme';
import Filters from '@/page-components/Search/Filters';
import { colorVariables, miles } from '@/constants';
import SearchHeader from '@/page-components/Search/SearchHeader';
import { getCategories, getGeoCity } from '@/lib/api/functions';
import { Category } from '@/lib/types/interfaces/Category';
import { City } from '@/lib/types/interfaces/City';
import { SubCategoriesState } from '@/lib/types/interfaces/SubCategoriesState';
import { SearchContext } from '@/lib/types/interfaces/SearchContext';
import { SearchFormFields } from '@/lib/types/interfaces/SearchFormFields';

const Footer = dynamic(() => import('@/components/Footer'));

const subCategoriesInitialState: SubCategoriesState = {
  parentId: null,
  list: [],
  checkbox: {},
};
export const SearchLayoutContext = createContext<SearchContext>({
  term: null,
  location: null,
  categories: [],
  currentCategory: null,
  setSubCategories: () => {},
  setCurrentCategory: () => {},
  fetchLocation: async () => {},
  subCategoriesState: subCategoriesInitialState,
});

const SearchLayout = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const path = usePathname();
  const router = useRouter();
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategoriesState, setSubCategories] = useState<SubCategoriesState>(subCategoriesInitialState);
  const [location, setLocation] = useState<City | null>(null);
  const isHomePage = path === '/';
  const searchParams = useSearchParams();
  const queryParams = new URLSearchParams(searchParams.toString());
  const route = useRouter();
  const [term, setTermName] = useState<string | null>(queryParams.get('term')?.toString() || '');
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
  const currentCategoryPath = path.split('/')[2];

  const fetchLocation = useCallback(async (locationId: string) => {
    if (locationId) {
      const locationResponse = await getGeoCity(locationId);
      setLocation(locationResponse?.data || null);
    }
  }, [queryParams.has('loc')]);

  const fetchData = useCallback(async () => {
    try {
      const response = await getCategories();
      setCategories(response.data);

      if (currentCategoryPath) {
        const category = response.data.find(item => item.slug === currentCategoryPath) || null;
        setCurrentCategory(category);
      }
      if (queryParams.has('loc') && queryParams?.get('loc')?.toString()) {
        await fetchLocation(queryParams.get('loc')!.toString());
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleClearFilters = () => {
    queryParams.delete('loc');
    queryParams.delete('term');
    queryParams.delete('radius');

    route.push('/hire');
    setSubCategories(subCategoriesInitialState);
    setLocation(null);
    setTermName('');
    setCurrentCategory(null);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (queryParams.has('loc') && queryParams?.get('loc')?.toString()) {
      fetchLocation(queryParams.get('loc')!.toString());
    }

    if (!queryParams.has('loc') && location?.id) {
      setLocation(null);
    }
  }, [queryParams.get('loc')]);

  useEffect(() => {
    setTermName(queryParams.get('term')?.toString() || '');
  }, [queryParams.get('term')]);

  useEffect(() => {
    if (categories.length) {
      const category = categories.find(item => item.slug === currentCategoryPath) || null;
      setCurrentCategory(category);
    }
  }, [currentCategoryPath]);

  return (
    <Suspense>
      <SearchLayoutContext.Provider value={useMemo(() => ({
        term,
        location,
        categories,
        fetchLocation,
        currentCategory,
        setSubCategories,
        subCategoriesState,
        setCurrentCategory,
      }), [currentCategory, subCategoriesState, location, term, fetchLocation])}
      >
        <Formik
          initialValues={{
            location,
            business: term || '',
          }}
          onSubmit={async (values: SearchFormFields) => {
            values?.location?.id ? queryParams.set('loc', String(values?.location?.id)) : queryParams.delete('loc');
            values.business ? queryParams.set('term', String(values.business)) : queryParams.delete('term');
            values?.location?.id ? queryParams.set('radius', queryParams.get('radius') || miles[0]) : queryParams.delete('radius');
            queryParams.delete('page');
            const queryPath = queryParams.toString() ? `?${queryParams.toString()}` : '';
            if (isHomePage) {
              router.push(`/hire${queryPath}`);
              return;
            }
            if (!path.includes('/hire')) {
              router.push(`/hire${queryPath}`);
              return;
            }
            router.push(`${path}${queryPath}`);
          }}
        >
          {({ setFieldValue }) => (
            <>
              <SearchHeader />
              <main>
                <Grid
                  container
                  spacing={0}
                >
                  {isDesktop && (
                    <Grid
                      item
                      lg={2}
                    >
                      <Filters categories={categories} />
                      <Box
                        padding={3}
                        position='sticky'
                        bottom={0}
                        bgcolor='white'
                      >
                        <Button
                          variant='outlined'
                          color='success'
                          fullWidth
                          onClick={async () => {
                            await setFieldValue('location', '');
                            await setFieldValue('business', '');
                            handleClearFilters();
                          }}
                        >
                          Clear Filters
                        </Button>
                      </Box>
                    </Grid>
                  )}
                  <Grid
                    item
                    lg={10}
                    sx={{
                      borderLeft: `1px solid ${colorVariables.separatorColor}`,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {children}
                  </Grid>
                </Grid>
              </main>
              <Footer />
            </>
          )}
        </Formik>
      </SearchLayoutContext.Provider>
    </Suspense>
  );
};
export default SearchLayout;
