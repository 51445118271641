'use client';

import React, {
  createContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Button,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { usePathname, useRouter } from 'next/navigation';
import dynamic from 'next/dynamic';
import theme from '@/theme';
import Filters from '@/page-components/Services/Filters';
import { colorVariables } from '@/constants';
import SearchHeader from '@/page-components/Search/SearchHeader';
import { getCategories, getLocationSearch } from '@/lib/api/functions';
import { Category } from '@/lib/types/interfaces/Category';
import { useQuery } from '@/hooks/useQuery';
import { City } from '@/lib/types/interfaces/City';

const Footer = dynamic(() => import('@/components/Footer'));

interface SearchContext {
  currentCategory?: Category | null;
  categories?: Category[];
  subCategories?: Category[];
  location?: City | null;
  term?: string | null
  setCurrentCategory: (category: Category | null) => void
}

export const ServicesLayoutContext = createContext<SearchContext>({
  location: null,
  categories: [],
  subCategories: [],
  term: null,
  currentCategory: null,
  setCurrentCategory: () => {},
});

const ServicesLayout = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const route = useRouter();
  const { queryParams } = useQuery();
  const [term] = useState<string | null>(queryParams.get('term')?.toString() || '');
  const [location, setLocation] = useState<City | null>(null);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
  const path = usePathname();
  const pathItems = path.split('/');

  const fetchData = useCallback(async () => {
    try {
      const response = await getCategories();
      setCategories(response.data);

      const category = response.data.find(item => pathItems.includes(item.slug)) || null;
      setCurrentCategory(category);

      const subCategoriesResponse = category?.id && await getCategories([category.id]);
      subCategoriesResponse && setSubCategories(subCategoriesResponse.data);
    } catch (err) {
      console.error(err);
    }
  }, [path]);

  const fetchLocation = useCallback(async () => {
    let state;
    let city;

    const stateIndex = pathItems.findIndex(item => item.length === 2);

    if (stateIndex !== -1) {
      state = pathItems[stateIndex];
      city = pathItems[stateIndex + 1];
    }

    if (city && state) {
      try {
        const response = await getLocationSearch(city);

        setLocation(
          response?.data
            .find((loc) => loc.state === state && loc.name === city.replaceAll('_', ' '))
          || null,
        );
      } catch (error) {
        console.error(error);
      }
    }
  }, [path]);

  useEffect(() => {
    fetchData();
    fetchLocation();
  }, [path]);

  const handleClearFilters = () => {
    route.push('/services');
    setCurrentCategory(null);
  };

  return (
    <ServicesLayoutContext.Provider value={useMemo(() => ({
      term,
      location,
      categories,
      subCategories,
      currentCategory,
      setCurrentCategory,
    }), [currentCategory, subCategories, term, location])}
    >
      <SearchHeader />
      <main>
        <Grid
          container
          spacing={0}
        >
          {isDesktop && (
            <Grid
              item
              lg={2}
            >
              <Filters
                categories={categories}
              />
              <Box
                padding={3}
                position='sticky'
                bottom={0}
                bgcolor='white'
              >
                <Button
                  variant='outlined'
                  color='success'
                  fullWidth
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </Button>
              </Box>
            </Grid>
          )}
          <Grid
            item
            lg={10}
            sx={{
              borderLeft: `1px solid ${colorVariables.separatorColor}`,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}
          </Grid>
        </Grid>
      </main>
      <Footer />
    </ServicesLayoutContext.Provider>
  );
};
export default ServicesLayout;
