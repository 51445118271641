'use client';

import Link from 'next/link';
import { Button, Box, useMediaQuery } from '@mui/material';

import { usePathname } from 'next/navigation';
import Logo from '@/components/Logo';
import theme from '@/theme';
import SearchForm from '@/components/SearchForm';
import SearchHeaderControls from '@/page-components/Search/SearchHeader/SearchHeaderControls';
import { headerControlsHeight } from '@/constants';

const SearchHeader = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const pathname = usePathname();
  const isListing = pathname === '/add-listing';

  return (
    <header className='header-container'>
      <Box
        sx={{
          [theme.breakpoints.up('lg')]: {
            display: 'flex',
            alignItems: 'center',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        }}
      >
        <Link
          href='/'
          aria-label='Get back to main page'
          style={{
            marginRight: '35px',
          }}
        >
          <Logo />
        </Link>
      </Box>
      <Box
        display={{ xs: 'none', lg: 'block' }}
        marginRight='auto'
      >
        <SearchForm />
      </Box>
      {!isDesktop && (
        <Box width='100%'>
          <SearchHeaderControls />
        </Box>
      )}
      <Box display={isListing ? 'none' : 'block'}>
        <Link
          href='/add-listing'
          aria-label='Go to listing'
          legacyBehavior
          passHref
        >
          <Button
            href='/add-listing'
            variant='outlined'
            sx={{
              height: headerControlsHeight,
              display: 'none',
              [theme.breakpoints.up('lg')]: {
                width: 197,
                display: 'flex',
              },
            }}
          >
            Add Your Business
          </Button>
        </Link>
      </Box>

    </header>
  );
};

export default SearchHeader;
