'use client';

import React, {
  SyntheticEvent, useContext, useEffect, useState, useRef,
} from 'react';
import {
  Button, InputAdornment, TextField, Autocomplete, Box,
  useMediaQuery,
} from '@mui/material';
import { useFormik, useFormikContext } from 'formik';
import SearchIcon from '@mui/icons-material/Search';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { usePathname, useRouter } from 'next/navigation';

import { handleGTMEvent } from '@/lib/utils/functions';
import { colorVariables, headerControlsHeight, miles } from '@/constants';
import useDebounce from '@/hooks/useDebounce';
import theme from '@/theme';
import { LocationSearch } from '@/lib/types/interfaces/LocationSearch';
import { getLocationSearch } from '@/lib/api/functions';
import { SearchLayoutContext } from '@/page-components/Search/SearchLayout/SearchLayout';
import { useQuery } from '@/hooks/useQuery';
import { SearchFormFields } from '@/lib/types/interfaces/SearchFormFields';
import { ServicesLayoutContext } from '@/page-components/Services/ServicesLayout/ServicesLayout';

const SearchForm = () => {
  const path = usePathname();
  const formikContext = useFormikContext<SearchFormFields>();
  const isMobileSearchPlaceholder = useMediaQuery(theme.breakpoints.down(325));
  const { queryParams } = useQuery();
  const locationRef = useRef();
  const businessRef = useRef();
  const isSearch = path.startsWith('/hire/');
  // @ts-ignore
  const { location, term } = useContext(isSearch ? SearchLayoutContext : ServicesLayoutContext);
  const router = useRouter();
  const [locations, setLocations] = useState<LocationSearch[]>([]);
  const [inputValue, setInputValue] = useState('');
  const isHomePage = path === '/';
  const formik = formikContext || useFormik<SearchFormFields>({
    initialValues: {
      location: location || null,
      business: term || '',
    },
    onSubmit: async (values) => {
      values?.location?.id ? queryParams.set('loc', String(values?.location?.id)) : queryParams.delete('loc');
      values.business ? queryParams.set('term', String(values.business)) : queryParams.delete('term');
      values?.location?.id ? queryParams.set('radius', queryParams.get('radius') || miles[0]) : queryParams.delete('radius');
      const queryPath = queryParams.toString() ? `?${queryParams.toString()}` : '';
      let url = '';

      if (isHomePage) {
        url = `/hire${queryPath}`;
        handleGTMEvent(url, 'view_search_results');
        router.push(url);
        return;
      }
      if (!path.includes('/hire')) {
        url = `/hire${queryPath}`;
        handleGTMEvent(url, 'view_search_results');
        router.push(url);
        return;
      }
      url = `${path}${queryPath}`;
      handleGTMEvent(url, 'view_search_results');
      router.push(url);
    },
  });

  useDebounce(async () => {
    if (inputValue) {
      const locationsData = await getLocationSearch(String(inputValue));
      setLocations(locationsData?.data || []);
    }
  }, 300, [inputValue]);

  const handleInputLocation = async (_: React.SyntheticEvent, value: string, reason: string) => {
    setInputValue(value);

    if (!value) {
      setLocations([]);
    }

    if (reason === 'clear') {
      setLocations([]);
    }
  };

  const handleSetLocation = async (_: SyntheticEvent<Element, Event>, value: string | LocationSearch | null) => {
    await formik.setFieldValue('location', value);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && (document.activeElement === locationRef.current || document.activeElement === businessRef.current)) {
      formik.handleSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (location?.id) {
      formik.setFieldValue('location', location);
    }
  }, [location?.id]);

  return (
    <Box
      marginRight='auto'
      display='flex'
      alignItems='center'
      flexDirection={{ xs: 'column', lg: 'row' }}
    >
      <TextField
        placeholder={isMobileSearchPlaceholder ? 'How can we help?' : 'What do you need help with?'}
        variant='outlined'
        inputRef={businessRef}
        value={formik.values.business}
        InputProps={{
          style: {
            ...(!isHomePage && {
              height: headerControlsHeight,
            }),
          },
          onChange: (event) => {
            formik.setFieldValue('business', event.target.value);
          },
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiInputBase-root': {
            [theme.breakpoints.up('lg')]: {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          },
          width: '100%',
          [theme.breakpoints.up('lg')]: {
            borderRight: 'none',
            width: 330,
          },
        }}
      />
      <Autocomplete
        id='location'
        freeSolo
        value={formik.values.location}
        isOptionEqualToValue={(option, value) => option === value}
        inputValue={inputValue}
        onChange={handleSetLocation}
        onInputChange={handleInputLocation}
        getOptionLabel={(option) => {
          if (typeof option === 'string') return option;
          return `${option.name || ''}, ${option.state || ''}`;
        }}
        options={locations}
        sx={{
          [theme.breakpoints.down('lg')]: {
            alignSelf: 'stretch',
          },
        }}
        renderOption={(props, option) => (
          <Box
            {...props}
            key={option.id}
            component='li'
            sx={{
              '&:hover': {
                backgroundColor: `${colorVariables.cardColor} !important`,
              },
            }}
          >
            {option.name}
            { ', ' }
            {option.state}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Location'
            fullWidth
            margin='normal'
            inputRef={locationRef}
            InputProps={{
              ...params.InputProps,
              style: {
                ...(!isHomePage && {
                  height: headerControlsHeight,
                  padding: 'inherit',
                  paddingLeft: 8,
                }),
              },
              startAdornment: (
                <InputAdornment position='start'>
                  <PlaceOutlinedIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              marginTop: 2,
              width: '100%',
              minWidth: 'auto',
              header: headerControlsHeight,
              '& .MuiInputBase-root': {
                [theme.breakpoints.up('lg')]: {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                },
              },
              [theme.breakpoints.up('lg')]: {
                width: 'auto',
                minWidth: 236,
                marginTop: 0,
                marginBottom: 0,
                marginRight: 3,
              },
            }}
          />
        )}
      />

      <Button
        variant='contained'
        color='success'
        size='small'
        sx={{
          marginTop: 4,
          color: 'white',
          height: isHomePage ? 58 : headerControlsHeight,
          width: '100%',
          [theme.breakpoints.up('lg')]: {
            width: 118,
            marginTop: 0,
          },
        }}
        onClick={() => formik.handleSubmit()}
      >
        Search
      </Button>
    </Box>
  );
};

export default SearchForm;
