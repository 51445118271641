import {
  Suspense, useContext, useMemo, useState,
} from 'react';
import Link from 'next/link';
import { Button, Typography, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styles from './SearchHeaderControls.module.scss';
import { colorVariables } from '@/constants';
import Img from '@/components/Img';
import { SearchLayoutContext } from '@/page-components/Search/SearchLayout/SearchLayout';
import theme from '@/theme';
import SearchDrawer from '../../SearchDrawer/SearchDrawer';
import FiltersDrawer from '../../FiltersDrawer/FiltersDrawer';

const SearchHeaderControls = () => {
  const isMobileSearchPlaceholder = useMediaQuery(theme.breakpoints.down(425));
  const { location, term } = useContext(SearchLayoutContext);
  const buttonText = useMemo(() => {
    if (location && term) return `${term} in ${location.name}`;
    if (location) return `In ${location.name}`;
    if (term) return term;
    return isMobileSearchPlaceholder ? 'How can we help?' : 'What do you need help with?';
  }, [location, term, isMobileSearchPlaceholder]);
  const [openSearchDrawer, setOpenSearchDrawer] = useState(false);
  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);

  return (
    <Suspense>
      <header className={styles.wrapper}>
        <Link
          href='/'
          aria-label='Get back to main page'
          className={styles.link}
        >
          <Img
            src='/media/brandmark-logo.webp'
            alt='Logo icon'
            height={25}
            width={25}
            withPreloader
          />
        </Link>
        <Button
          variant='outlined'
          sx={{
            border: `1px solid ${colorVariables.separatorColor}`,
            color: 'unset',
            textTransform: 'none',
            flexGrow: 1,
            paddingRight: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
          onClick={() => setOpenSearchDrawer(true)}
        >
          <Typography
            fontWeight={400}
            fontSize={16}
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            color={colorVariables.textDescription}
          >
            {buttonText}
          </Typography>
          <SearchIcon
            color='success'
            sx={{ marginLeft: 2 }}
          />
        </Button>

        <Typography
          color={colorVariables.primaryColor}
          onClick={() => setOpenFiltersDrawer(true)}
          fontWeight={600}
          fontSize={14}
          marginLeft={2}
        >
          Filters
        </Typography>

        <SearchDrawer
          open={openSearchDrawer}
          toggleDrawer={setOpenSearchDrawer}
        />
        <FiltersDrawer
          open={openFiltersDrawer}
          toggleDrawer={setOpenFiltersDrawer}
        />
      </header>
    </Suspense>
  );
};

export default SearchHeaderControls;
