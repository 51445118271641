'use client';

import {
  Box,
  Radio,
  Checkbox,
  Accordion,
  FormGroup,
  RadioGroup,
  Typography,
  AccordionDetails,
  FormControlLabel,
  AccordionSummary,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import React, {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';

import { usePathname, useRouter } from 'next/navigation';
import { Category } from '@/lib/types/interfaces/Category';
import {
  breakCrumbsHeight, colorVariables, miles, searchResultsHeight, servicesListHeight,
} from '@/constants';
import { getCategories } from '@/lib/api/functions';
import { getIdsFromSlug } from '@/lib/utils/functions';
import { ServicesLayoutContext } from '@/page-components/Services/ServicesLayout/ServicesLayout';
import { useQuery } from '@/hooks/useQuery';
import apiClient from '@/apiClient';
import { DTO } from '@/lib/types/interfaces/DTO';
import { City } from '@/lib/types/interfaces/City';

interface Props {
  children?: React.ReactNode;
  categories: Category[]
}

interface SubCategoriesState {
  parentId: null | number;
  list: Category[],
  checkbox: Record<string, boolean>
}

const subCategoriesInitialState: SubCategoriesState = {
  parentId: null,
  list: [],
  checkbox: {},
};

const Filters = ({ children, categories }: Props) => {
  const route = useRouter();
  const path = usePathname();
  const { queryParams } = useQuery();
  const { currentCategory, setCurrentCategory, subCategories } = useContext(ServicesLayoutContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [category, setCategory] = useState<null | Category>(currentCategory || null);
  const [subCategoriesState, setSubCategories] = useState<SubCategoriesState>(subCategoriesInitialState);
  const [selectedMiles, setSelectedMiles] = useState('0');
  const [isSelectedMilesVisible, setIsSelectedMilesVisible] = useState(false);
  const pathItems = path.split('/');

  const handleChange = (value: string) => {
    const categoryValue = categories?.find((item) => item.slug === value);
    setCurrentCategory(categoryValue!);
    setSubCategories(subCategoriesInitialState);
  };

  const subCategoryFromPath = useMemo(() => {
    const slug = pathItems[3];
    const subcategory = subCategoriesState.list.find((item) => pathItems.includes(item.slug));
    return {
      slug: subcategory?.slug,
      subcategory,
      isSubCategory: slug && slug.length > 2,
      path: slug ? `/${slug}` : '',
    };
  }, [subCategoriesState]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    queryParams.append('subCategory', String(subCategoryFromPath.subcategory?.id));

    if (event.target.name !== subCategoryFromPath.slug) {
      queryParams.append('subCategory', String(getIdsFromSlug(subCategoriesState.list, [event.target.name])[0]));
    }
    route.push(`/hire/${category?.slug}?${queryParams.toString()}`);
  };

  const handleCategories = (filter: Category) => {
    queryParams.toString();
    queryParams.delete('page');

    const query = queryParams.toString();
    route.push(`/services/${filter?.slug}${query ? `?${query}` : ''}`);
    setCategory((prevState) => {
      if (prevState?.id === filter.id) {
        setIsExpanded(!isExpanded);
        return prevState;
      }
      setIsExpanded(true);
      setSubCategories(subCategoriesInitialState);
      return filter;
    });
  };

  const fetchSubCategories = async (filter: Category) => {
    if (filter.id !== subCategoriesState.parentId) {
      try {
        return await getCategories([filter.id]);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const renderFilterData = useMemo(() => categories?.map(filter => (
    <Accordion
      sx={{ boxShadow: 'unset', position: 'unset', wordBreak: 'break-word' }}
      expanded={filter.id === subCategoriesState?.parentId && isExpanded}
      key={filter.id}
      disableGutters
      onChange={() => handleCategories(filter)}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls='panel1-content'
        id='panel1-header'
        sx={{
          minHeight: '42px',
          '& .MuiAccordionSummary-content': {
            marginTop: 0,
            marginBottom: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            visibility: category?.id === filter.id && !subCategoriesState.list.length ? 'hidden' : 'visible',
          },
        }}
      >
        <FormControlLabel
          value={filter.slug}
          control={<Radio />}
          label=''
        />
        <Typography
          variant='body1'
          fontWeight={400}
          display='flex'
          alignItems='center'
          fontSize={14}
        >
          {filter.name}
        </Typography>
      </AccordionSummary>

      {filter.id === subCategoriesState?.parentId && !!subCategoriesState?.list?.length && (
        <AccordionDetails
          sx={{
            padding: '8px 16px 16px',
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
          }}
        >
          {category?.id === subCategoriesState.parentId && (subCategoriesState.list.map(subcategory => (
            <Box
              key={`subcategory-${subcategory.id}`}
              sx={{
                marginLeft: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={subCategoriesState.checkbox[subcategory.slug]}
                    onChange={handleCheckboxChange}
                    name={subcategory.slug}
                    id={String(subcategory.id)}
                    sx={{ fontSize: 14, padding: '0 9px' }}
                  />
                )}
                label={subcategory.name}
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontSize: 14,
                  },
                }}
              />
            </Box>
          )) || null)}
        </AccordionDetails>
      )}
    </Accordion>
  ) || null), [subCategoriesState, categories, isExpanded]);

  const fetchData = useCallback(async () => {
    if (category) {
      try {
        const subCategoriesResponse = await fetchSubCategories(category);
        const activeSubcategory = subCategoriesResponse?.data.find(item => pathItems.includes(item.slug));

        if (subCategoriesResponse?.data?.length && category?.id) {
          setSubCategories(() => ({
            parentId: category.id,
            list: subCategoriesResponse.data,
            checkbox: {
              ...Object.fromEntries(subCategoriesResponse.data.map((item) => [item.slug, false])),
              ...(activeSubcategory && { [activeSubcategory.slug]: true }),
            },
          }));
        }
      } catch (error: any) {
        console.error(error);
      }
    }
  }, [category?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (currentCategory) {
      setCategory(currentCategory);
      setIsExpanded(true);
    } else {
      setCategory(null);
      setIsExpanded(false);
    }
  }, [currentCategory]);

  useEffect(() => {
    const stateIndex = pathItems.findIndex(item => item.length === 2);
    const state = stateIndex !== -1 ? pathItems[stateIndex] : '';
    const city = stateIndex !== -1 ? pathItems[stateIndex + 1] : '';

    if (state?.length === 2 && city?.length) {
      setIsSelectedMilesVisible(true);
    } else {
      setIsSelectedMilesVisible(false);
    }
  }, [path]);

  const getCities = async (query: string) => apiClient.get <DTO<City[]>>(`/geo/cities?${query}`, { cache: 'no-cache' });

  const handleMilesChange = async (e: SelectChangeEvent) => {
    const newRadius = e.target.value;
    setSelectedMiles(newRadius);

    const newSearchParams = new URLSearchParams(queryParams.toString());
    newSearchParams.set('radius', newRadius);

    const stateIndex = pathItems.findIndex(item => item.length === 2);
    const state = stateIndex !== -1 ? pathItems[stateIndex] : '';
    const city = stateIndex !== -1 ? pathItems[stateIndex + 1] : '';
    const subcategory = stateIndex > 3 ? pathItems[stateIndex - 1] : '';
    const subcategoryId = subcategory && subCategories?.find((item) => item.slug === subcategory)?.id;

    if (subcategoryId) {
      newSearchParams.set('subCategory', String(subcategoryId));
    }

    const locationQuery = `state=${state}&city=${city}`;

    try {
      const { data } = await getCities(locationQuery);

      if (category) {
        route.push(`/hire/${category?.slug}?loc=${data[0].id}&${newSearchParams.toString()}`);
      } else {
        route.push(`/hire?loc=${data[0].id}&${newSearchParams.toString()}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        padding: '16px 0 16px 16px',
        borderLeft: `1px solid ${colorVariables.separatorColor}`,
        maxHeight: searchResultsHeight + servicesListHeight + breakCrumbsHeight,
        overflow: 'auto',
      }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
      >
        <Typography
          variant='body1'
          fontWeight={700}
        >
          Filters
        </Typography>
        {children}
      </Box>
      {isSelectedMilesVisible && (
        <Box
          display='flex'
          alignItems='center'
          mr={1}
          mt={1}
          sx={{ gap: '12px' }}
        >
          <Typography
            fontSize={14}
            sx={{ textWrap: 'nowrap' }}
          >
            Results within
          </Typography>
          <Select
            labelId='miles-label'
            id='miles'
            value={selectedMiles}
            onChange={handleMilesChange}
            sx={{
              minWidth: 100,
              fontSize: 14,
              '.MuiSelect-select': {
                padding: '10px 32px 10px 14px',
                color: selectedMiles === '0' ? '#a2a2a2' : 'auto',
              },
            }}
          >
            <MenuItem
              disabled
              value='0'
              sx={{
                display: 'none',
              }}
            >
              Select
            </MenuItem>
            {miles.map((mile) => (
              <MenuItem
                value={mile}
                sx={{
                  fontSize: 14,
                }}
              >
                {`${mile} miles`}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
      <Typography
        variant='body1'
        fontWeight={400}
        marginTop={1}
      >
        Service Category
      </Typography>

      <Box
        marginTop={2}
      >
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          defaultValue=''
          name='radio-buttons-group'
          value={category?.slug || ''}
          onChange={(event) => handleChange(event.target.value)}
        >
          {renderFilterData}
        </RadioGroup>
      </Box>
      <FormGroup />
      <Box />
    </Box>
  );
};

export default Filters;
