import {
  Drawer, Box,
  TextField, InputAdornment,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { usePathname, useRouter } from 'next/navigation';
import { useFormik } from 'formik';
import { colorVariables, headerControlsHeight, miles } from '@/constants';
import DrawerProps from '@/lib/types/interfaces/Drawer';
import { LocationSearch } from '@/lib/types/interfaces/LocationSearch';
import useDebounce from '@/hooks/useDebounce';
import { getLocationSearch } from '@/lib/api/functions';
import { SearchLayoutContext } from '@/page-components/Search/SearchLayout/SearchLayout';
import theme from '@/theme';
import { ServicesLayoutContext } from '@/page-components/Services/ServicesLayout/ServicesLayout';

const SearchDrawer = ({ open, toggleDrawer }: DrawerProps) => {
  const route = useRouter();
  const path = usePathname();
  const isMobileSearchPlaceholder = useMediaQuery(theme.breakpoints.down(325));
  const isSearch = path.startsWith('/hire/');
  // @ts-ignore
  const { location, term } = useContext(isSearch ? SearchLayoutContext : ServicesLayoutContext);
  const [currentLocation, setCurrentLocation] = useState<null | LocationSearch>(null);
  const [locations, setLocations] = useState<LocationSearch[]>([]);
  const formik = useFormik<{
    location: LocationSearch | null,
    business: string
  }>({
    initialValues: {
      location: null,
      business: term || '',
    },
    onSubmit: (values) => {
      const newSearchParams = new URLSearchParams();
      newSearchParams.delete('loc');
      newSearchParams.delete('term');
      newSearchParams.delete('radius');

      if (values.business) {
        newSearchParams.set('term', values.business);
      }
      if (values.location) {
        newSearchParams.set('loc', String(currentLocation?.id));
        newSearchParams.set('radius', miles[0]);
      }

      route.push(`/hire${newSearchParams.toString() ? `?${newSearchParams.toString()}` : ''}`);
      toggleDrawer(false);
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setCurrentLocation(null);
    route.replace('/hire');
    toggleDrawer(false);
  };

  useEffect(() => {
    if (location) formik.setFieldValue('location', location.name || location);
  }, [location]);

  useDebounce(async () => {
    const isLocation = locations.some((item) => item.name === String(formik.values.location));
    if (formik.values.location && !isLocation) {
      const locationsData = await getLocationSearch(String(formik.values.location));
      setLocations(locationsData?.data || []);
      return;
    }
    setLocations([]);
  }, 300, [formik.values.location]);

  const renderLocationData = useMemo(() => locations.map((item) => (
    <Box
      key={item.id}
      paddingY={2}
      borderBottom={`1px solid ${colorVariables.separatorColor}`}
      onClick={() => {
        formik.setFieldValue('location', `${item.name}, ${item.state}`);
        setCurrentLocation(item);
      }}
    >
      <Typography
        variant='body1'
        fontWeight={400}
      >
        {`${item.name}, ${item.state}`}
      </Typography>
    </Box>
  )), [locations]);

  return (
    <Drawer
      open={open}
      anchor='top'
      onClose={() => toggleDrawer(false)}
      sx={{
        '& .MuiDrawer-paper': {
          height: '100%',
        },
      }}
    >
      <Box
        paddingX={2}
        paddingY={2}
        borderBottom={`1px solid ${colorVariables.separatorColor}`}
      >
        <TextField
          label={isMobileSearchPlaceholder ? 'How can we help?' : 'What do you need help with?'}
          variant='outlined'
          value={formik.values.business}
          InputProps={{
            style: {
              height: headerControlsHeight,
            },
            onChange: (event) => {
              formik.setFieldValue('business', event.target.value);
            },
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <TextField
          variant='outlined'
          label='Location'
          value={formik.values.location}
          InputProps={{
            style: {
              height: headerControlsHeight,
            },
            onChange: (event) => {
              formik.setFieldValue('location', event.target.value);
            },
            startAdornment: (
              <InputAdornment position='start'>
                <PlaceOutlinedIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
          sx={{
            marginTop: 2,
            header: headerControlsHeight,
          }}
        />
      </Box>

      <Box
        overflow='scroll'
        paddingX={3}
        paddingY={1}
      >
        {renderLocationData}
      </Box>

      <Box
        paddingX={2}
        paddingY={2}
        display='flex'
        flexDirection='column'
        marginTop='auto'
        gap={2}
        onClick={() => formik.handleSubmit()}
      >
        <Button
          variant='contained'
          color='success'
          sx={{
            minHeight: 56,
          }}
        >
          Search
        </Button>
        <Button
          variant='outlined'
          color='success'
          onClick={handleCancel}
          sx={{
            minHeight: 56,
          }}
        >
          Cancel
        </Button>
      </Box>
    </Drawer>
  );
};

export default SearchDrawer;
